import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import RegenerativePledge from "../components/common/regenerativePledge";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import PartOfFamily from "../components/aboutus/part_of_family";
import Seo from "../components/seo";

const AboutUs = ({ data: { sanityAboutPage, sanitySitesettings } }) => {
  let seo;
  let hero;
  let breadcrumb;
  let regenerative;

  sanityAboutPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "regenerative") {
      regenerative = element;
    }
  });

  return (
    <Layout>
      <Seo data={seo} />

      <>
        <Hero heroSection={hero} h2Size="text-3xl sm:text-[70px]" h2P="pb-7" />
        <Logo logo={sanitySitesettings?.logo} />
      </>

      <PartOfFamily
        h1={sanityAboutPage?.h1}
        h2={sanityAboutPage?.h2}
        h3={sanityAboutPage?.h3}
        txt={sanityAboutPage?.txt}
        btnTxt={sanityAboutPage?.btnTxt}
        coverImg={sanityAboutPage?.coverImg}
        detail={sanityAboutPage?._rawDetail}
        videoUrl={sanityAboutPage?.url}
      />
      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      <Instagram />
      {regenerative && (
        <div>
          <RegenerativePledge data={regenerative} />
          <Logo logo={sanitySitesettings?.logo} />
        </div>
      )}
    </Layout>
  );
};

export default AboutUs;

export const query = graphql`
  {
    sanityAboutPage {
      pageBuilder {
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          ctaButton {
            link
            title
            type
            variant
            formId
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityRegenerative {
          _type
          heading
          _rawContent
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
        }
      }
      h1
      h2
      h3
      txt
      btnTxt
      url
      _rawDetail
      coverImg {
        caption
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, formats: WEBP, placeholder: BLURRED)
        }
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
        }
      }
    }
  }
`;
