import React, { useEffect } from "react";

export default function Wrapper({ children, onClose, bg = "bg-[#ccc]" }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <div className={`fixed top-0 bottom-0 left-0 right-0 z-50 h-full ${bg}`}>
      <div className="relative flex items-center justify-center h-full">
        <div
          className="absolute right-5 top-4 cursor-pointer p-1 group"
          onClick={onClose}
        >
          <svg
            width="26"
            height="26"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.35314 4.45939C3.2805 4.38675 3.22288 4.30051 3.18357 4.20561C3.14426 4.1107 3.12402 4.00899 3.12402 3.90626C3.12402 3.80354 3.14426 3.70182 3.18357 3.60691C3.22288 3.51201 3.2805 3.42577 3.35314 3.35314C3.42577 3.2805 3.51201 3.22288 3.60691 3.18357C3.70182 3.14426 3.80354 3.12402 3.90626 3.12402C4.00899 3.12402 4.1107 3.14426 4.20561 3.18357C4.30051 3.22288 4.38675 3.2805 4.45939 3.35314L12.5 11.3953L20.5406 3.35314C20.6133 3.2805 20.6995 3.22288 20.7944 3.18357C20.8893 3.14426 20.991 3.12402 21.0938 3.12402C21.1965 3.12402 21.2982 3.14426 21.3931 3.18357C21.488 3.22288 21.5742 3.2805 21.6469 3.35314C21.7195 3.42577 21.7771 3.51201 21.8165 3.60691C21.8558 3.70182 21.876 3.80354 21.876 3.90626C21.876 4.00899 21.8558 4.1107 21.8165 4.20561C21.7771 4.30051 21.7195 4.38675 21.6469 4.45939L13.6047 12.5L21.6469 20.5406C21.7195 20.6133 21.7771 20.6995 21.8165 20.7944C21.8558 20.8893 21.876 20.991 21.876 21.0938C21.876 21.1965 21.8558 21.2982 21.8165 21.3931C21.7771 21.488 21.7195 21.5742 21.6469 21.6469C21.5742 21.7195 21.488 21.7771 21.3931 21.8165C21.2982 21.8558 21.1965 21.876 21.0938 21.876C20.991 21.876 20.8893 21.8558 20.7944 21.8165C20.6995 21.7771 20.6133 21.7195 20.5406 21.6469L12.5 13.6047L4.45939 21.6469C4.38675 21.7195 4.30051 21.7771 4.20561 21.8165C4.1107 21.8558 4.00899 21.876 3.90626 21.876C3.80354 21.876 3.70182 21.8558 3.60691 21.8165C3.51201 21.7771 3.42577 21.7195 3.35314 21.6469C3.2805 21.5742 3.22288 21.488 3.18357 21.3931C3.14426 21.2982 3.12402 21.1965 3.12402 21.0938C3.12402 20.991 3.14426 20.8893 3.18357 20.7944C3.22288 20.6995 3.2805 20.6133 3.35314 20.5406L11.3953 12.5L3.35314 4.45939Z"
              fill={bg === "bg-transparent" ? "black" : "white"}
            />
          </svg>
          <div className="absolute -top-[3px] -right-[3px] group-hover:border-2 h-10 w-10 border-[#333] rounded-full " />
        </div>
        <div className="relative w-full  h-full mt-[90%]  sm:mt-[35%] lg:mt-[10%] 2xl:mt-[15%] mx-3 md:w-[1000px] player-show">
          {children}
        </div>
      </div>
    </div>
  );
}
