import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
// import TowerYouTube from "../../common/popups/TowerYouTube";
import PortableText from "react-portable-text";
import IframeVideo from "../../common/popups/IframeVideo";

const PartOfFamily = ({
  h1,
  h2,
  h3,
  txt,
  btnTxt,
  coverImg,
  detail,
  videoUrl,
}) => {
  const [popup, setPopup] = useState(false);

  const togglePopup = () => {
    setPopup(!popup);
  };
  return (
    <div className="relative block box-border">
      <div className="relative max-w-[1140px] w-full mx-auto">
        <div className="relative flex flex-col flex-wrap justify-center box-border">
          {/* Image Container */}
          <div className="w-[70%] relative block mx-auto pt-5 pb-4">
            <div
              className="block rounded-[5px] overflow-hidden show"
              style={{
                boxShadow: "0px 0px 18px rgb(0 0 0 / 50%)",
              }}
            >
              <GatsbyImage
                image={coverImg?.asset?.gatsbyImageData}
                alt={coverImg?.alt}
                className="w-full h-full object-cover"
                objectFit="cover"
                loading="eager"
                placeholder="dominantColor"
              />
            </div>
          </div>
          {/* Content */}
          <div className="relative block box-border">
            <div className="relative min-h-[80vh] flex flex-col flex-wrap justify-center box-border">
              {/* Heading */}
              <div className="w-full show">
                <div className="block pb-5 mt-2">
                  <h1 className="text-3xl sm:text-6xl text-assent text-center  mb-3 font-bold tracking-[2px]">
                    {h1}
                  </h1>
                </div>
              </div>
              {/* Subtitle 1 */}
              <div className="w-full show">
                <div className="block pb-5">
                  <h2 className="text-3xl sm:text-[40px] font-bold text-[#666666] text-center mb-3 uppercase">
                    {h2}
                  </h2>
                </div>
              </div>
              {/* Subtitle 2 */}
              <div className="w-full show">
                <div className="block pb-5">
                  <h3 className="text-3xl sm:text-[40px] font-bold text-[#666666] text-center mb-3 uppercase">
                    {h3}
                  </h3>
                </div>
              </div>
              <div className="w-[90%] relative block mx-auto mb-5 show">
                <div className="block w-full">
                  {detail && (
                    <PortableText
                      content={detail}
                      className="font-semibold text-[#323A44] block-content max-w-none"
                    />
                  )}
                </div>
              </div>
              <div className="relative block w-[65%] mx-auto font-bold show">
                <div className="block w-full pb-[15px]">
                  <p className="text-center text-[#323a44] uppercase">{txt}</p>
                </div>
              </div>
              <div className="w-[95%] mx-auto show">
                <div className="flex flex-row flex-wrap max-w-full align-top items-stretch justify-around">
                  <div className="relative block pt-5 pb-10 m-[1px]">
                    <button
                      className="inline-block px-[40px] rounded-tl-[27px] rounded-br-[27px] h-[45px] text-sm leading-[38px] font-extrabold uppercase
                      text-center no-underline whitespace-nowrap btn-secondary 
                      text-white box-border transition-all duration-200 ease-linear tracking-[2px] w-full"
                      onClick={togglePopup}
                      aria-label="popup-video-tower"
                    >
                      {btnTxt}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popup && (
        <IframeVideo onClose={togglePopup} bg="transparent" url={videoUrl} />
      )}
    </div>
  );
};

export default PartOfFamily;
