import React from "react";
import Wrapper from "./Wrapper";

export default function IframeVideo({ onClose, bg, url }) {
  return (
    <Wrapper onClose={onClose} bg={bg ? `bg-${bg}` : `bg-[#ccc]`}>
      <div className="relative overflow-hidden w-full md:w-[800px] lg:w-[900px]  mx-auto h-[40%] sm:h-[80%]">
        <iframe
          className={
            "mx-auto absolute top-0 bg-black left-0 right-0 bottom-0 w-full h-full"
          }
          src={url}
          title="iframe-player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Wrapper>
  );
}
